import dsgLogo from 'images/clients/dsg/logo.svg';
import dsgBackgroundDesktopDE from 'images/clients/dsg/img-header-background_desktop_de.png';
import dsgBackgroundMobileDE from 'images/clients/dsg/img-header-background_mobile_de.png';
import dsgIconCertificateDsg from 'images/clients/dsg/icon-certificates.svg';
import dsgIconSecuredVaultDsg from 'images/clients/dsg/icon-secured-vault.svg';
import dsgIconShareDsg from 'images/clients/dsg/icon-share.svg';
import dsgIconSecurity from 'images/clients/dsg/icon-security.svg';
import dsgIconEmergency from 'images/clients/dsg/icon-emergency-access.svg';
import dsgIconFlexible from 'images/clients/dsg/icon-flexible.svg';
import dsgFeatureBackgroundDesktopDE from 'images/clients/dsg/img-feature-background_de.png';
import dsgFeatureBackgroundMobileDE from 'images/clients/dsg/img-feature-background_mobile_de.png';
import dsgDocumentBackgroundDE from 'images/clients/dsg/img-document-background.png';
import dsgDocumentBackgroundMobileDE from 'images/clients/dsg/img-document-background_mobile.png';
import dsgIconDocument from 'images/clients/dsg/icon-documents.svg';
import dsgManagementBackgroundDE from 'images/clients/dsg/img-management.png';
import dsgManagementBackgroundMobileDE from 'images/clients/dsg/img-management_mobile.png';
import dsgIconPersonalized from 'images/clients/dsg/icon-personalized.svg';
import dsgIconHeartPhone from 'images/clients/dsg/icon-heart-phone.svg';
import dsgIconDesktop from 'images/clients/dsg/icon-desktop.svg';
import dsgSecurityBackground from 'images/clients/dsg/img-security-section-background.png';
import dsgIconEncrypted from 'images/clients/dsg/icon-encrypted.svg';
import dsgIconFlag from 'images/clients/dsg/icon-geman-flag.svg';
import dsgIconPassword from 'images/clients/dsg/icon-password-hash.svg';
import dsgIconDataSecurity from 'images/clients/dsg/icon-data-security.svg';
import dsgSection9Background from 'images/clients/dsg/img-bottom-background.png';
import dsgFooterLogo from 'images/clients/dsg/logo_footer.svg';

import hdhLogo from 'images/clients/hdh/logo.png';

import spkvLogo from 'images/clients/spkv/logo.png';
import spkvBackgroundDesktopDE from 'images/clients/spkv/img-header-background.png';
import spkvBackgroundMobileDE from 'images/clients/spkv/img-header-background-mobile.png';
import plusCircledIcon from 'images/icon-plus-circle.svg';
import spkvManagementBackgroundDE from 'images/clients/spkv/img-management.png';

import bvagLogo from 'images/clients/bvag/logo.png';

import diebayerischeLogo from 'images/clients/diebayerische/logo.png';
import diebayerischeBackgroundDesktopDE from 'images/clients/diebayerische/img-header-background.png';
import diebayerischeBackgroundMobileDE from 'images/clients/diebayerische/img-header-background-mobile.png';
import diebayerischeCustomClientSection1DesktopDE from 'images/clients/diebayerische/img-custom-section-1.png';
import diebayerischeCustomClientSection1MobileDE from 'images/clients/diebayerische/img-custom-section-1-mobile.png';

import vbbbLogo from 'images/clients/vbbb/logo.png';
import vbbbBackgroundDesktopDE from 'images/clients/vbbb/img-header-background.png';
import vbbbBackgroundMobileDE from 'images/clients/vbbb/img-header-background_mobile.png';

import dbkBackgroundDesktopDE from 'images/clients/dbk/img-header-background.png';
import dbkBackgroundMobileDE from 'images/clients/dbk/img-header-background-mobile.png';
import dbkCustomClientSection1DesktopDE from 'images/clients/dbk/img-custom-section-1.png';
import dbkCustomClientSection1MobileDE from 'images/clients/dbk/img-custom-section-1-mobile.png';

import c24BackgroundDesktopDE from 'images/clients/c24/img-header-background.png';
import c24BackgroundMobileDE from 'images/clients/c24/img-header-background-mobile.png';
import c24CustomClientSection1DesktopDE from 'images/clients/c24/img-custom-section-1.png';
import c24CustomClientSection1MobileDE from 'images/clients/c24/img-custom-section-1-mobile.png';

export const CLIENT_KEYS = {
  DSG: 'dsg',
  IDEAL: 'ideal',
  VBBB: 'vbbb',
  HDH: 'hdh',
  SPKV: 'spkv',
  AU: 'au-vorsorge',
  BVAG: 'bv-ag',
  BAYERISCHE: 'diebayerische',
  DBK: 'dbk',
  C24: 'c24',
};

const CK = CLIENT_KEYS;

export const CLIENT_CUSTOMIZATIONS = {
  [CK.DSG]: {
    logo: dsgLogo,
    favicon: true,
    homepage: 'https://www.deutsche-schutzbrief.de/',
    // replace Ninebarc terminology
    // make sure to replace it if new texts contain Ninebarc are introduced
    customizedTexts: {
      section1Desc: true,
      section2Header: true,
      section3Desc: true,
      section6Texts: true,
      section8Texts: true,
    },

    hideHeaderRegistrationBtn: true,

    section1Background: {
      desktopEN: dsgBackgroundDesktopDE,
      desktopDE: dsgBackgroundDesktopDE,
      mobileEN: dsgBackgroundMobileDE,
      mobileDE: dsgBackgroundMobileDE,
    },

    section2Advantages: [
      dsgIconCertificateDsg,
      dsgIconSecuredVaultDsg,
      dsgIconShareDsg,
    ],
    noVideoBtn: true,

    section3Background: {
      desktopEN: dsgFeatureBackgroundDesktopDE,
      desktopDE: dsgFeatureBackgroundDesktopDE,
      mobileEN: dsgFeatureBackgroundMobileDE,
      mobileDE: dsgFeatureBackgroundMobileDE,
    },
    features: [dsgIconSecurity, dsgIconEmergency, dsgIconFlexible],

    section4Background: {
      desktopEN: dsgDocumentBackgroundDE,
      desktopDE: dsgDocumentBackgroundDE,
      mobileEN: dsgDocumentBackgroundMobileDE,
      mobileDE: dsgDocumentBackgroundMobileDE,
    },
    iconDocument: dsgIconDocument,

    section5Background: {
      desktopEN: dsgManagementBackgroundDE,
      desktopDE: dsgManagementBackgroundDE,
      mobileEN: dsgManagementBackgroundMobileDE,
      mobileDE: dsgManagementBackgroundMobileDE,
    },
    managementIcons: [dsgIconPersonalized, dsgIconHeartPhone, dsgIconDesktop],
    noTrial: true,
    noOneTimePrice: true,
    monthlyPrice: 2.41,

    section7Background: dsgSecurityBackground,
    dataSecurities: [
      dsgIconEncrypted,
      dsgIconFlag,
      dsgIconPassword,
      dsgIconDataSecurity,
    ],
    section9Background: dsgSection9Background,
    footerGrid: 'lg:t-grid lg:t-grid-cols-4 lg:t-gap-3',
    footerTextClass: 't-text-gamma-400',
    footerLogo: dsgFooterLogo,
    footerContactLabel: 'dsg_footer_contact_title',
    footerContactTexts: [
      'dsg_footer_contact_text_1',
      'dsg_footer_contact_text_2',
      'dsg_footer_contact_text_3',
      'dsg_footer_contact_text_4',
    ],
    registerButton: 'dsg_register_button_text',
  },
  [CK.VBBB]: {
    rightClientNavEl: true,
    partnership: {
      upperText: 'vbbb_in_partnership_with',
      logo: vbbbLogo,
      logoStyle: 't-w-40 mx-auto t-block',
      link: 'https://www.vb-bruchsal-bretten.de/',
    },
    hideHeaderRegistrationBtn: true,
    customizedTexts: {
      section1Desc: true,
    },
    mobileCooperationLogo: true,
    noDesktopNav: true,
    headerLoginButton: true,
    section1Background: {
      desktopEN: vbbbBackgroundDesktopDE,
      desktopDE: vbbbBackgroundDesktopDE,
      mobileEN: vbbbBackgroundMobileDE,
      mobileDE: vbbbBackgroundMobileDE,
    },
    yearlyPriceRecommended: true,
    customYearlyPrice: 'vbbb_section_6_price_yearly_price',
    customLifetimePrice: 'vbbb_section_6_price_one_time_price',
    customPrivacy: true,
  },
  [CK.HDH]: {
    rightClientNavEl: true,
    headerRightDivClass: 't-relative t-w-0 md:t-w-32',
    noDesktopNav: true,
    partnership: {
      logo: hdhLogo,
      logoStyle: 't-absolute -t-top-11 t-w-32 t-h-28',
      link: 'https://www.sterbegeld-hdh.de',
    },
    embeddedVideoLink: 'https://www.youtube.com/embed/LnuQMKBhPYg',
    noPriceQuotes: true,
    customPriceSectionTitle: 'hdh_price_section_title',
    customPriceSectionDesc: 'hdh_price_section_desc',
  },
  [CK.SPKV]: {
    section1Background: {
      desktopEN: spkvBackgroundDesktopDE,
      desktopDE: spkvBackgroundDesktopDE,
      mobileEN: spkvBackgroundMobileDE,
      mobileDE: spkvBackgroundMobileDE,
    },
    partnership: {
      upperText: 'spkv_in_partnership_with',
      logo: spkvLogo,
      logoStyle: 't-w-40',
      link: 'https://www.sparkassenversicherung.de',
    },
    section2Benefits: ['', '', 'spkv_section_2_benefit_3'],
    section5Background: {
      desktopEN: spkvManagementBackgroundDE,
      desktopDE: spkvManagementBackgroundDE,
      mobileEN: spkvManagementBackgroundDE,
      mobileDE: spkvManagementBackgroundDE,
    },
    managementTitleKeys: ['', '', 'spkv_section_5_management_title_3'],
    managementTextKeys: [
      'spkv_section_5_management_desc_1',
      '', // keep as default
      'spkv_section_5_management_desc_3',
    ],
    rightClientNavEl: true,
    section5LogoHeader: true,
    noDesktopNav: true,
    yearlyPriceRecommended: true,
    customYearlyPrice: 'spkv_section_6_price_yearly_price',
    customLifetimePrice: 'spkv_section_6_price_one_time_price',
    customPriceBenefitIcon: plusCircledIcon,
  },
  [CK.BVAG]: {
    rightClientNavEl: true,
    noDesktopNav: true,
    partnership: {
      logo: bvagLogo,
      logoStyle: 't-w-64',
      link: 'https://vorsorgechampion.de',
    },
  },
  [CK.BAYERISCHE]: {
    rightClientNavEl: true,
    noDesktopNav: true,
    partnership: {
      upperText: 'diebayerische_in_partnership_with',
      logo: diebayerischeLogo,
      logoStyle: 't-w-60',
      link: 'https://www.diebayerische.de',
    },
    section1Background: {
      desktopEN: diebayerischeBackgroundDesktopDE,
      desktopDE: diebayerischeBackgroundDesktopDE,
      mobileEN: diebayerischeBackgroundMobileDE,
      mobileDE: diebayerischeBackgroundMobileDE,
    },
    customizedTexts: {
      section1Desc: true,
    },
    customClientSection1: true,
    customClientSection1Background: {
      desktopEN: diebayerischeCustomClientSection1DesktopDE,
      desktopDE: diebayerischeCustomClientSection1DesktopDE,
      mobileEN: diebayerischeCustomClientSection1MobileDE,
      mobileDE: diebayerischeCustomClientSection1MobileDE,
    },
    section2Benefits: ['', '', 'diebayerische_section_2_benefit_3'],
    yearlyPriceRecommended: true,
    customYearlyPrice: 'diebayerische_section_6_price_yearly_price',
    customLifetimePrice: 'diebayerische_section_6_price_one_time_price',
  },
  [CK.DBK]: {
    rightClientNavEl: true,
    noDesktopNav: true,
    customClientSection1: true,
    customClientSection1Background: {
      desktopEN: dbkCustomClientSection1DesktopDE,
      desktopDE: dbkCustomClientSection1DesktopDE,
      mobileEN: dbkCustomClientSection1MobileDE,
      mobileDE: dbkCustomClientSection1MobileDE,
    },
    section1Background: {
      desktopEN: dbkBackgroundDesktopDE,
      desktopDE: dbkBackgroundDesktopDE,
      mobileEN: dbkBackgroundMobileDE,
      mobileDE: dbkBackgroundMobileDE,
    },
    customizedTexts: {
      section1Desc: true,
      section1Title: true,
      section2Desc: true,
    },
    section2Benefits: [
      'dbk_section_2_benefit_1',
      '',
      'dbk_section_2_benefit_3',
    ],
    yearlyPriceRecommended: true,
    customYearlyPrice: 'dbk_section_6_price_yearly_price',
    customLifetimePrice: 'dbk_section_6_price_one_time_price',
    customPriceSectionDesc: 'dbk_price_section_desc',
  },
  [CK.C24]: {
    rightClientNavEl: true,
    noDesktopNav: true,
    customClientSection1: true,
    customClientSection1Background: {
      desktopEN: c24CustomClientSection1DesktopDE,
      desktopDE: c24CustomClientSection1DesktopDE,
      mobileEN: c24CustomClientSection1MobileDE,
      mobileDE: c24CustomClientSection1MobileDE,
    },
    section1Background: {
      desktopEN: c24BackgroundDesktopDE,
      desktopDE: c24BackgroundDesktopDE,
      mobileEN: c24BackgroundMobileDE,
      mobileDE: c24BackgroundMobileDE,
    },
    customizedTexts: {
      section1Desc: true,
      section1Title: true,
      section2Desc: true,
    },
    section2Benefits: [
      'c24_section_2_benefit_1',
      '',
      'c24_section_2_benefit_3',
    ],
    yearlyPriceRecommended: true,
    customYearlyPrice: 'c24_section_6_price_yearly_price',
    customLifetimePrice: 'c24_section_6_price_one_time_price',
    customPriceSectionDesc: 'c24_price_section_desc',
  },
};
