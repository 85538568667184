import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import axios from 'axios';
import { isEmpty } from 'lodash';
import { Accordion } from 'react-bootstrap';

import config from 'helpers/configHelper';

import {
  DesktopNav,
  HyperlinkTracking,
  MobileNav,
  RegisterButton,
} from 'components';

import { CLIENT_KEYS } from 'constants/clientCustomizations';

import logo from 'images/logo.svg';
import sandwich from 'images/sandwich.svg';
import cross from 'images/cross.svg';
import { ReactComponent as IconDown } from 'images/icon-arrow-down.svg';
import { ReactComponent as IconUp } from 'images/icon-arrow-up.svg';
import imgAgent from 'images/avatar_agent_1.svg';
import imgCompany from 'images/avatar_agent_company.svg';
import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';
import { Partnership } from 'components/common';

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

interface HeaderProps {
  registerLink: string;
  token?: string;
  client?: string;
  customization?: ClientCustomization;
}

function Header({ registerLink, token, client, customization }: HeaderProps) {
  const { t } = useTranslation();

  const [agentInfo, setAgentInfo] = React.useState({} as AgentDTO);

  const handleFetchingAgentData = async () => {
    if (!token || token.length !== 40) return;
    const response = await axios.post(`${config.beUrl}/auth/agent`, { token });
    if (!!response?.data?.email) {
      setAgentInfo(response.data);
    }
  };

  React.useEffect(() => {
    if (!!token) {
      handleFetchingAgentData();
    }
  }, []);

  const [showMobileMenu, setShowMobileMenu] = useState(false);
  const [showAgentInfo, setShowAgentInfo] = useState(false);

  const toggleMenu = () => {
    setShowMobileMenu(!showMobileMenu);
  };

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  const handleShowMobileMenu = (show: boolean) => {
    setShowMobileMenu(show);
  };

  const navElements: NavObject[] = [
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction typo-gamma ${client}_custom_font`,
      text: t('header_about_us'),
      href: 'about-us',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction typo-gamma ${client}_custom_font`,
      text: t('header_features'),
      href: 'features',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction typo-gamma ${client}_custom_font`,
      text: t('header_experiences'),
      href: 'experiences',
    },
    {
      liClassName: 'Header-navigationListItem',
      elClassName: `Header-navigationAction typo-gamma ${client}_custom_font`,
      text: t('header_price'),
      href: 'prices',
    },
  ];

  const shouldShowAgentInfo =
    !isEmpty(agentInfo) && client === CLIENT_KEYS.IDEAL;
  const { name, surname, company, gender, profilePicture, email } = agentInfo;
  const agentName = `${name || ''}${surname ? ` ${surname}` : ''}`;
  const avatar =
    profilePicture ||
    (!agentInfo?.name && !agentInfo?.surname ? imgCompany : imgAgent);

  const displayedAgentInfo = shouldShowAgentInfo
    ? { agentName, gender, avatar, company, email }
    : {};

  const {
    rightClientNavEl,
    noDesktopNav,
    headerRightDivClass = '',
    headerLoginButton,
  } = customization || {};

  return (
    <header>
      <div className="t-max-w-xl t-my-0 t-mx-auto t-px-2.5">
        <div
          className={
            'Header t-py-6 t-flex t-items-center t-relative' +
            (showMobileMenu && !shouldShowAgentInfo ? ' isActive' : '')
          }
        >
          {!shouldShowAgentInfo && !noDesktopNav && (
            <button
              className="Header-mobileToggler"
              onClick={toggleMenu}
              type="button"
            >
              <img src={showMobileMenu ? cross : sandwich} alt="menu" />
            </button>
          )}

          <HyperlinkTracking
            href={customization?.homepage || 'https://www.ninebarc.de'}
            trackingId="website"
          >
            <img
              src={customization?.logo || logo}
              alt="logo"
              className={`t-h-12 ${client}_logo`}
            />
          </HyperlinkTracking>

          {!shouldShowAgentInfo && !noDesktopNav && (
            <MobileNav
              navElements={navElements}
              showMobileMenu={showMobileMenu}
              setShowMobileMenu={handleShowMobileMenu}
              registerLink={registerLink}
              customization={customization}
              client={client}
            />
          )}

          {!noDesktopNav && (
            <DesktopNav
              navElements={navElements}
              agentInfo={displayedAgentInfo}
              client={client}
            />
          )}

          {!shouldShowAgentInfo && (
            <div className={`${headerRightDivClass}`}>
              {!rightClientNavEl && (
                <ul className="t-flex t-items-center">
                  {!customization?.hideHeaderRegistrationBtn && (
                    <li className="t-hidden md:t-inline-block">
                      <RegisterButton
                        registerLink={registerLink}
                        className="button-tertiary t-px-8 t-py-4 t-shadow-lg t-shadow-alpha-200"
                        buttonText={t('header_register')}
                        onClick={hideMobileMenu}
                        customization={customization}
                      />
                    </li>
                  )}
                </ul>
              )}

              {headerLoginButton ? (
                <div className="t-hidden lg:t-block t-absolute t-right-64 t-top-9">
                  <a
                    href={`${config.feUrl || ''}/login`}
                    target="_blank"
                    rel="noopener noreferrer nofollow"
                    className="button-primary"
                  >
                    {t('header_login')}
                  </a>
                </div>
              ) : null}

              {rightClientNavEl && (
                <Partnership customization={customization} />
              )}
            </div>
          )}
        </div>

        {!!shouldShowAgentInfo && (
          <div className="xl:t-hidden t-mx-3">
            <Accordion
              className="t-w-full card t-py-4 md:t-py-6 t-px-3"
              onSelect={() => {
                setShowAgentInfo((prev) => !prev);
              }}
            >
              <Accordion.Item eventKey="agent">
                <Accordion.Button
                  as="div"
                  className="t-w-full t-cursor-pointer"
                >
                  <div className=" t-flex t-justify-between t-items-center ">
                    <p className="Text-small t-text-delta-700">
                      {gender === 'female'
                        ? t('header_agent_title_female')
                        : t('header_agent_title')}
                    </p>
                    {showAgentInfo ? (
                      <IconUp className="t-text-delta-700 t-h-2.5" />
                    ) : (
                      <IconDown className="t-text-delta-700 t-h-2.5" />
                    )}
                  </div>
                </Accordion.Button>

                <Accordion.Body>
                  <div className="t-flex t-mt-3 t-items-center">
                    <img
                      src={avatar}
                      alt="Agent"
                      className="t-mr-2 t-w-12 t-h-12 t-rounded-full t-object-cover"
                    />
                    <div>
                      <p className="Text-small">
                        <span>{agentName}</span>
                        {!!company && (
                          <span className="t-font-semibold"> ({company})</span>
                        )}
                      </p>
                      <div className="t-mt-2">
                        <a
                          className="t-text-delta-700 t-outline-none t-no-underline typo-eta t-whitespace-nowrap t-flex"
                          href={`mailto:${email}`}
                        >
                          <span>
                            <EmailIcon className="t-mr-3 t-mt-0.5 t-text-delta-700" />
                          </span>
                          {t('header_agent_email_button')}
                        </a>
                      </div>
                    </div>
                  </div>
                </Accordion.Body>
              </Accordion.Item>
            </Accordion>
          </div>
        )}
      </div>
    </header>
  );
}

export default Header;
