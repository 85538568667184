import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';

import imgSecurityBackground from 'images/img-security-section-background.png';
import iconEncrypted from 'images/icon-encrypted.svg';
import iconFlag from 'images/icon-geman-flag.svg';
import iconPassword from 'images/icon-password-hash.svg';
import iconDataSecurity from 'images/icon-data-security.svg';

import ssl from 'images/ssl.png';
import makeInGermany from 'images/made-in-germany.png';
import allianz from 'images/allianz-for-cyber-security.png';
import familie from 'images/familie.png';

const securities = [
  {
    name: 'SSL SICHERE Verbindung',
    logo: ssl,
    className: 't-h-20',
  },
  {
    name: 'Made In Germany',
    logo: makeInGermany,
    className: 't-h-20',
  },
  {
    name: 'Allianz für Cyber-Sicherheit',
    logo: allianz,
    className: 't-h-20',
  },
  {
    name: 'Erfolgsfaktor Familie',
    logo: familie,
    className: 't-h-20',
  },
];

interface LandingPageSection7Props {
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection7({
  client,
  customization,
}: LandingPageSection7Props) {
  const { t } = useTranslation();

  const customizedIcons = customization?.dataSecurities || [];

  const dataSecurities = [
    {
      icon: customizedIcons[0] || iconEncrypted,
      title: 'section_7_security_1_title',
      text: 'section_7_security_1_desc',
    },
    {
      icon: customizedIcons[1] || iconFlag,
      title: 'section_7_security_2_title',
      text: 'section_7_security_2_desc',
    },
    {
      icon: customizedIcons[2] || iconPassword,
      title: 'section_7_security_3_title',
      text: 'section_7_security_3_desc',
    },
    {
      icon: customizedIcons[3] || iconDataSecurity,
      title: 'section_7_security_4_title',
      text: 'section_7_security_4_desc',
    },
  ];

  return (
    <div className="t-flex t-justify-center t-pt-24 t-pb-32 t-mt-16 t-relative">
      <img
        src={customization?.section7Background || imgSecurityBackground}
        alt="Security background"
        className="t-absolute t-top-0 t-w-full t-z-0"
      />
      <div className="t-max-w-xl t-z-50">
        <p className={`Text-header-sub t-text-center ${client}_section_title`}>
          {t('section_7_title')}
        </p>

        <p
          className={`Text-normal t-mt-8 t-text-center ${client}_section_desc`}
        >
          {t('section_7_description')}
        </p>

        <div className="t-w-full t-flex t-flex-col t-items-center lg:t-grid lg:t-grid-cols-2 lg:t-gap-9 t-mt-10 lg:t-mt-14">
          {dataSecurities.map(({ icon, title, text }) => (
            <div
              key={title}
              className="t-flex t-flex-col lg:t-flex-row t-items-center t-mt-8 lg:t-mt-0"
            >
              <div
                className="t-rounded t-shadow-md t-flex t-justify-center t-items-center lg:t-mr-10"
                style={{ width: '72px', height: '72px' }}
              >
                <img src={icon} alt={title} />
              </div>

              <div className="t-max-w-112 lg:t-pr-10 t-text-center lg:t-text-left">
                <p
                  className={`Text-normal t-mt-4 lg:t-mt-0 ${client}_text_title`}
                >
                  {t(title)}
                </p>
                <p
                  className={`Text-normal t-font-normal t-mt-1 ${client}_text_desc`}
                >
                  {t(text)}
                </p>
              </div>
            </div>
          ))}
        </div>

        <p
          className={`Text-header-small t-text-delta-700 t-text-center t-mt-28 ${client}_section_desc`}
        >
          {t('section_7_tech_title')}
        </p>

        <div className="t-w-full t-mt-12">
          <div className="t-w-full">
            <ul className="t-flex t-items-center t-justify-center lg:t-justify-around t-flex-wrap">
              {securities.map(({ name, logo }) => (
                <li className="t-my-5 md:t-my-0 t-mx-5" key={name}>
                  <motion.img
                    src={logo}
                    alt={name}
                    className="t-h-12"
                    whileHover={{ scale: 1.5 }}
                  />
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
}
