import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { scrollToElementId } from 'helpers';
import { HyperlinkTracking } from 'components';

import logo from 'images/logo.svg';

const mediaLinks = [
  {
    name: 'linkedin',
    href: 'https://www.linkedin.com/company/ninebarc/',
    text: 'footer_social_media_text_1',
  },
  {
    name: 'facebook',
    href: 'https://www.facebook.com/Ninebarc',
    text: 'footer_social_media_text_2',
  },
  {
    name: 'pinterest',
    href: 'https://www.pinterest.de/ninebarcofficial/',
    text: 'footer_social_media_text_3',
  },
];

const contactInfo = ['footer_contact_text_1', 'footer_contact_text_2', ''];

interface FooterProps {
  client?: string;
  customization?: ClientCustomization;
}

function Footer({ client, customization }: FooterProps) {
  const { t } = useTranslation();

  const textColor = customization?.footerTextClass || '';

  return (
    <footer className={`t-flex t-justify-center t-pt-10 ${client}_footer`}>
      <div className="t-max-w-xl t-w-full t-mt-2 t-mb-36 t-px-10 md:t-px-20 lg:t-px-0">
        <div className="t-w-full t-flex t-flex-col lg:t-flex-row t-justify-between">
          <div className="t-flex t-mx-4 lg:t-mr-20">
            <HyperlinkTracking
              href={customization?.homepage || 'https://www.ninebarc.de'}
              trackingId="website"
              className="t-outline-none"
            >
              <img
                src={customization?.footerLogo || logo}
                alt="logo"
                className="t-max-h-20 Logo"
              />
            </HyperlinkTracking>
          </div>

          <div
            className={`t-flex t-flex-col t-mx-4 t-mt-10 lg:t-mt-0 ${
              customization?.footerGrid ||
              'lg:t-grid lg:t-grid-cols-4 lg:t-gap-20'
            }`}
          >
            <div>
              <p className={`Text-small ${textColor}`}>
                {t('footer_function_title')}
              </p>
              <div
                className="t-flex t-flex-col t-text-beta-500"
                style={{ fontSize: '14px', lineHeight: '17px' }}
              >
                <p
                  className={`t-mt-4 t-cursor-pointer ${textColor}`}
                  onClick={() => {
                    scrollToElementId('features');
                  }}
                >
                  {t('footer_function_text_1')}
                </p>
                <p
                  className={`t-mt-4 t-cursor-pointer t-whitespace-nowrap ${textColor}`}
                  onClick={() => {
                    scrollToElementId('documents');
                  }}
                >
                  {t('footer_function_text_2')}
                </p>
                <p
                  className={`t-mt-4 t-cursor-pointer ${textColor}`}
                  onClick={() => {
                    scrollToElementId('recommendations');
                  }}
                >
                  {t('footer_function_text_3')}
                </p>
              </div>
            </div>

            <div className="t-mt-12 lg:t-mt-0">
              <p className={`Text-small ${textColor}`}>
                {t('footer_legal_title')}
              </p>
              <div
                className="t-flex t-flex-col t-text-beta-500"
                style={{ fontSize: '14px', lineHeight: '17px' }}
              >
                <HyperlinkTracking
                  href="https://www.ninebarc.de/imprint"
                  className={`t-mt-4 t-outline-none ${textColor}`}
                  trackingId="imprint"
                  key="imprint"
                >
                  {t('footer_legal_text_1')}
                </HyperlinkTracking>
                <HyperlinkTracking
                  href={
                    customization?.customPrivacy
                      ? `https://www.ninebarc.de/privacy-${client}`
                      : 'https://www.ninebarc.de/privacy'
                  }
                  className={`t-mt-4 t-outline-none ${textColor}`}
                  trackingId="privacy"
                  key="privacy"
                >
                  {t('footer_legal_text_2')}
                </HyperlinkTracking>
                <HyperlinkTracking
                  href="https://www.ninebarc.de/terms"
                  className={`t-mt-4 t-outline-none ${textColor}`}
                  trackingId="terms"
                  key="terms"
                >
                  {t('footer_legal_text_3')}
                </HyperlinkTracking>
              </div>
            </div>

            <div className="t-mt-12 lg:t-mt-0">
              <p className={`Text-small ${textColor}`}>
                {t('footer_social_media_title')}
              </p>
              <div
                className="t-flex t-flex-col t-items-start t-text-beta-500"
                style={{ fontSize: '14px', lineHeight: '17px' }}
              >
                {mediaLinks.map(({ href, name, text }) => (
                  <HyperlinkTracking
                    href={href}
                    className={`t-mt-4 t-outline-none ${textColor}`}
                    trackingId={name}
                    key={name}
                  >
                    {t(text)}
                  </HyperlinkTracking>
                ))}
              </div>
            </div>

            <div className="t-mt-12 lg:t-mt-0">
              <p className={`Text-small lg:t-whitespace-nowrap ${textColor}`}>
                {t(customization?.footerContactLabel || 'footer_contact_title')}
              </p>
              <div
                className="t-flex t-flex-col t-text-beta-500"
                style={{ fontSize: '14px', lineHeight: '17px' }}
              >
                {(customization?.footerContactTexts?.length
                  ? customization.footerContactTexts
                  : contactInfo
                ).map((text, idx) => (
                  <p
                    key={idx}
                    className={`t-mt-4 ${textColor}`}
                    dangerouslySetInnerHTML={{
                      __html: DOMPurify.sanitize(t(text)),
                    }}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
