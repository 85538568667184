import React from 'react';
import { useTranslation } from 'react-i18next';

import customer1 from 'images/customer1.png';
import customer2 from 'images/customer2.png';
import customer3 from 'images/customer3.jpg';
import { Carousel } from 'components';

const carouselBreakPoints = [
  {
    width: 1,
    itemsToShow: 1,
    itemsToScroll: 1,
    initialActiveIndex: 0,
  },
];

interface LandingPageSection8Props {
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection8({
  client,
  customization = {} as ClientCustomization,
}: LandingPageSection8Props) {
  const { t } = useTranslation();

  const { customizedTexts } = customization;
  const replacedWith = customizedTexts?.section8Text ? `${client}_` : '';

  const customerReviews = [
    {
      img: customer1,
      text: `${replacedWith}section_8_customer_1_review`,
      name: 'section_8_customer_1_name',
    },
    {
      img: customer2,
      text: `${replacedWith}section_8_customer_2_review`,
      name: 'section_8_customer_2_name',
    },
    {
      img: customer3,
      text: `${replacedWith}section_8_customer_3_review`,
      name: 'section_8_customer_3_name',
    },
  ];

  return (
    <div id="experiences">
      <div className="lg:t-flex t-justify-center lg:t-mt-32 lg:t-pb-32">
        <div className="t-max-w-xl">
          <p
            className={`Text-header-sub t-text-center ${client}_section_header`}
          >
            {t('section_8_title')}
          </p>

          <p
            className={`Text-normal t-mt-8 t-text-center ${client}_section_desc`}
          >
            {t(`${replacedWith}section_8_description`)}
          </p>

          <div
            className={`t-mt-12 lg:t-grid t-grid-cols-${customerReviews.length} t-gap-8 t-hidden`}
          >
            {customerReviews.map(({ img, text, name }, idx) => (
              <div
                className={`t-flex t-flex-col ${!!(idx % 2) ? 't-pt-10' : ''}`}
                key={name}
              >
                <div
                  key={name}
                  className={`t-border t-border-solid t-border-delta-200 t-p-8 t-max-w-64 t-rounded t-shadow-lg t-shadow-delta-200 ${client}_card_border`}
                >
                  <div className="t-rounded-full t-w-16 t-h-16 t-overflow-hidden">
                    <img src={img} alt="Customer review" />
                  </div>
                  <p
                    className={`Text-small t-font-normal t-text-beta-500 t-mt-5 ${client}_custom_font`}
                  >
                    {t(text)}
                  </p>

                  <div className="t-flex t-items-center t-mt-4">
                    <hr className="t-w-4 t-text-beta-400 t-mr-2" />
                    <p
                      className={`Text-header-small t-font-normal t-text-beta-200 ${client}_custom_font`}
                    >
                      {t(name)}
                    </p>
                  </div>
                </div>
              </div>
            ))}
          </div>

          <div className="lg:t-hidden t-mt-10">
            <Carousel
              breakPoints={carouselBreakPoints}
              adjustedBtnTop={64}
              adjustedBtnSide={-10}
              adjustedBtnSideLeft={10}
              extraInfo={{ client }}
            >
              {customerReviews.map(({ img, text, name }) => (
                <div key={name}>
                  <div
                    key={name}
                    className={`t-border t-border-solid t-border-delta-200 t-p-8 t-max-w-64 t-rounded t-shadow-lg t-shadow-delta-200 ${client}_card_border`}
                  >
                    <div className="t-rounded-full t-w-16 t-h-16 t-overflow-hidden">
                      <img src={img} alt="Customer review" />
                    </div>
                    <p
                      className={`Text-small t-font-normal t-text-beta-500 t-mt-5 ${client}_custom_font`}
                    >
                      {t(text)}
                    </p>

                    <div className="t-flex t-items-center t-mt-4">
                      <hr className="t-w-4 t-text-beta-400 t-mr-2" />
                      <p
                        className={`Text-header-small t-font-normal t-text-beta-200 ${client}_custom_font`}
                      >
                        {t(name)}
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
    </div>
  );
}
