import React from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useTranslation } from 'react-i18next';

interface RegisterButtonProps {
  registerLink: string;
  className?: string;
  buttonText?: string;
  onClick?: () => void;
  client?: string;
  customization?: ClientCustomization;
}

export default function RegisterButton({
  registerLink,
  className,
  buttonText,
  onClick,
  client,
  customization,
}: RegisterButtonProps) {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();

  const onTrackEvent = () => {
    trackEvent({
      category: 'links',
      action: 'register-link-click-event',
      name: 'register',
    });

    if (onClick) onClick;
  };

  return (
    <a
      href={registerLink}
      className={`${
        className ||
        'button-primary t-px-8 t-py-4 t-shadow-lg t-shadow-alpha-200 t-mt-5 lg:t-mt-0 lg:t-ml-8'
      } ${client}_register_btn`}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onTrackEvent}
    >
      {buttonText || t(customization?.registerButton || 'register_button_text')}
    </a>
  );
}
