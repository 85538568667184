import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegisterButton } from 'components';

import { ReactComponent as IconPointer } from 'images/icon-pointer.svg';

interface CustomClientSection1Props {
  registerLink?: string;
  client?: string;
  customization?: ClientCustomization;
}

export default function CustomClientSection1({
  registerLink,
  client,
  customization = {} as ClientCustomization,
}: CustomClientSection1Props) {
  const { t } = useTranslation();

  const { customClientSection1Background } = customization;

  return (
    <div>
      <div className="t-relative t-mt-10 xl:t-mt-32 xl:t-pb-32 t-flex t-justify-center t-overflow-hidden">
        <div className="t-absolute t-top-6 t-right-0 t-max-w-sm t-hidden lg:t-inline-block">
          <img
            src={customClientSection1Background?.desktopDE}
            alt="Client Section Background"
            className="t-max-w-96 t-w-64 xl:t-w-auto t-z-50"
          />
        </div>

        <div className="t-max-w-xl t-w-full t-mt-10 xl:t-mt-28 t-flex t-flex-col t-items-center lg:t-items-start lg:t-flex-row">
          <div className="t-mx-3 lg:t-p-0" style={{ maxWidth: '640px' }}>
            <p
              className={`Text-header-sub t-mt-4 t-text-center lg:t-text-start ${client}_section_header`}
            >
              {t(`${client}_custom_client_section_1_title`)}
            </p>

            <p
              className={`Text-normal t-mt-8 lg:t-pr-16 t-text-center lg:t-text-start ${client}_section_desc`}
            >
              {t(`${client}_custom_client_section_1_desc`)}
            </p>

            {!!registerLink && (
              <div className="lg:t-max-w-112 t-mt-5 lg:t-mt-11 t-mb-16 t-relative t-z-0">
                <RegisterButton
                  registerLink={registerLink}
                  client={client}
                  customization={customization}
                  className="button-primary t-px-8 t-py-4 t-shadow-lg t-shadow-alpha-200 t-mt-5 lg:t-mt-0"
                />
                <IconPointer
                  className={`t-absolute -t-right-28 -t-top-5 t-mt-6 t-hidden lg:t-inline-block t-text-alpha-600 ${client}_pointer`}
                  style={{ transform: 'rotate(-19deg)' }}
                />
              </div>
            )}
          </div>
        </div>
      </div>
      <div className="t-flex t-justify-center lg:t-hidden t-relative">
        <img
          src={customClientSection1Background?.mobileDE}
          alt="Ninebarc Features"
          className="t-z-50 t-max-w-96"
        />
        <div className="t-absolute t-w-full t-h-28 custom_client_section_1_footer t-bottom-0" />
      </div>

      <div className="t-w-full t-h-28 t-hidden lg:t-inline-block custom_client_section_1_footer t-z-0" />
    </div>
  );
}
