import React from 'react';
import { createInstance, MatomoProvider } from '@jonkoops/matomo-tracker-react';

import config from 'helpers/configHelper';

interface MatomoWrapperProps {
  siteId: number;
  children: React.ReactNode | React.ReactNode[];
}

function MatomoWrapper({ siteId, children }: MatomoWrapperProps) {
  // Create Matomo instance
  const matomoInstance = createInstance({
    urlBase: config.matomoBaseUrl || '',
    siteId,
    trackerUrl: `${config.matomoBaseUrl || ''}/matomo.php`,
    srcUrl: `${config.matomoBaseUrl || ''}/matomo.js`,
    linkTracking: true,
    disabled: !config.matomoBaseUrl,
  });

  return <MatomoProvider value={matomoInstance}>{children}</MatomoProvider>;
}

export default MatomoWrapper;
