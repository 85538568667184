import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import { LANGUAGES, LOCAL_STORAGE_VARS } from 'constants/common';

import en from 'localization/translations/en.json';
import de from 'localization/translations/de.json';

const lng =
  localStorage.getItem(LOCAL_STORAGE_VARS.SITE_LANGUAGE) || LANGUAGES.DE;
const fallbackLng = lng === LANGUAGES.EN ? LANGUAGES.DE : LANGUAGES.EN;

i18n.use(initReactI18next).init({
  lng,
  fallbackLng,
  debug: false,
  keySeparator: ' ',
  resources: {
    en: {
      translation: en,
    },
    de: {
      translation: de,
    },
  },
});

export default i18n;
