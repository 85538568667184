import React from 'react';
import { useTranslation } from 'react-i18next';
import { motion } from 'framer-motion';
import { isEmpty } from 'lodash';

import { scrollToElementId } from 'helpers';

import { CLIENT_KEYS } from 'constants/clientCustomizations';

import { ReactComponent as EmailIcon } from 'images/mail-icon.svg';

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

interface DesktopNavProps {
  navElements: NavObject[];
  agentInfo: FormattedAgentDTO;
  client?: string;
}

function DesktopNav({
  navElements,
  client,
  agentInfo = {} as FormattedAgentDTO,
}: DesktopNavProps) {
  const { t } = useTranslation();

  const shouldShowAgentInfo =
    !isEmpty(agentInfo) && client === CLIENT_KEYS.IDEAL;
  const { gender, agentName, avatar, company, email } = agentInfo;

  return (
    <nav
      className={`t-hidden xl:t-flex xl:t-justify-${
        !shouldShowAgentInfo ? 'center' : 'end'
      }`}
    >
      <ul className="t-flex">
        {!shouldShowAgentInfo &&
          navElements.map(({ liClassName, elClassName, text, href }, index) => (
            <motion.li
              className={liClassName}
              key={`nav-${index}`}
              variants={liVariants}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
            >
              <p
                className={elClassName}
                onClick={() => {
                  scrollToElementId(href);
                }}
              >
                {text}
              </p>
            </motion.li>
          ))}
        {shouldShowAgentInfo && (
          <div className="t-flex t-items-center">
            <div className="t-flex t-items-center t-w-full">
              <img
                src={avatar}
                alt="Agent"
                className="t-mr-2 t-w-12 t-h-12 t-rounded-full t-object-cover"
              />
              <div className="t-ml-2">
                <p className="Text-small t-text-delta-700">
                  {gender === 'female'
                    ? t('header_agent_title_female')
                    : t('header_agent_title')}
                </p>
                <p className="Text-small t-mt-2">
                  <span>{agentName}</span>
                  {!!company && (
                    <span className="t-font-semibold"> ({company})</span>
                  )}
                </p>
              </div>
            </div>
            <div>
              <a
                className="t-text-delta-700 t-outline-none t-no-underline typo-eta t-whitespace-nowrap t-flex t-ml-7"
                href={`mailto:${email}`}
              >
                <EmailIcon className="t-mr-3 t-mt-0.5 t-text-delta-700" />
                {t('header_agent_email_button')}
              </a>
            </div>
          </div>
        )}
      </ul>
    </nav>
  );
}

export default DesktopNav;
