import React from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

interface HyperlinkTrackingProps {
  href: string;
  children: React.ReactNode | React.ReactNode[];
  trackingId: string;
  className?: string;
  onClick?: () => void;
}

export default function HyperlinkTracking({
  href,
  className,
  trackingId,
  children,
  onClick,
}: HyperlinkTrackingProps) {
  const { trackEvent } = useMatomo();

  const onTrackEvent = () => {
    trackEvent({
      category: 'links',
      action: `${trackingId}-link-click-event`,
      name: trackingId,
    });

    if (onClick) onClick;
  };

  return (
    <a
      href={href}
      className={className}
      target="_blank"
      rel="noopener noreferrer"
      onClick={onTrackEvent}
    >
      {children}
    </a>
  );
}
