import React from 'react';
import { motion } from 'framer-motion';

import { ReactComponent as Arrow } from 'images/chevron-next.svg';

interface ArrowButtonProps {
  onClick: () => void;
  disabled: boolean;
  extraClass: string;
  isRight?: boolean;
  adjustedBtnTop?: number;
  adjustedBtnSide?: number;
  adjustedBtnSideLeft?: number;
  isAnimated?: boolean;
  extraInfo?: any;
}

export default function ArrowButton({
  onClick,
  disabled,
  isRight = true,
  extraClass,
  adjustedBtnTop = 18,
  adjustedBtnSide = 18,
  adjustedBtnSideLeft,
  isAnimated,
  extraInfo,
}: ArrowButtonProps) {
  return (
    <button
      onClick={onClick}
      disabled={disabled}
      className={`${extraClass} t-bg-delta-100 hover:t-bg-delta-200 t-w-12 t-h-12 t-rounded t-shadow ${
        extraInfo?.client ? `${extraInfo.client}_next_button` : ''
      }`}
      style={{
        top: `calc(50% - ${adjustedBtnTop}px)`,
        right: isRight ? `${-adjustedBtnSide}px` : '',
        left: !isRight ? `${adjustedBtnSideLeft || adjustedBtnSide}px` : '',
      }}
    >
      <motion.div
        className={`t-h-7 t-w-7 t-bg-delta-700 t-rounded-full t-m-auto t-text-center t-relative ${
          extraInfo?.client ? `${extraInfo.client}_next_button_bg` : ''
        }`}
        animate={{ scale: [1, isAnimated ? 0.8 : 1, 1] }}
        transition={{ duration: 2, ease: 'easeInOut', repeat: Infinity }}
        whileHover={{ scale: 1, transition: { duration: 0 } }}
      >
        <Arrow
          className={`t-self-center t-text-gamma-400 t-absolute t-w-4 t-top-3.5 t-left-1.5 ${
            extraInfo?.client ? `${extraInfo.client}_icon` : ''
          }`}
          style={{
            transform: !isRight
              ? 'translate(0, -50%) rotate(180deg)'
              : 'translate(0, -50%)',
          }}
        />
      </motion.div>
    </button>
  );
}
