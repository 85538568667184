import React from 'react';
import { useTranslation } from 'react-i18next';

import imgDocumentBackground from 'images/img-document-background.png';
import imgDocumentBackgroundMobile from 'images/img-document-background-mobile.png';
import iconDocument from 'images/icon-documents.svg';
import { ReactComponent as IconCheck } from 'images/icon-check.svg';

const texts = [
  {
    text: 'section_4_text_1',
    className: 't-max-w-36',
  },
  {
    text: 'section_4_text_2',
    className: 't-max-w-20',
  },
  {
    text: 'section_4_text_3',
    className: 't-max-w-36',
  },
  {
    text: 'section_4_text_4',
    className: 't-max-w-48',
  },
];

interface LandingPageSection4Props {
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection4({
  client,
  customization,
}: LandingPageSection4Props) {
  const { t } = useTranslation();

  return (
    <div id="documents">
      <img
        src={
          customization?.section4Background?.mobileDE ||
          imgDocumentBackgroundMobile
        }
        alt="Ninebarc platform"
        className="t-w-full t-mt-20 lg:t-hidden"
      />
      <div className="t-relative t-mt-10 lg:t-mt-32 lg:t-pb-32 t-overflow-hidden xxl2:t-flex xxl2:t-flex-col xxl2:t-items-center">
        <div className="t-absolute -t-left-4 t-hidden lg:t-inline-block">
          <img
            src={
              customization?.section4Background?.desktopDE ||
              imgDocumentBackground
            }
            alt="Ninebarc Features"
            style={{ maxWidth: '610px' }}
            className="t-w-96 xl:t-w-auto"
          />
        </div>

        <div className="t-absolute -t-right-10 t-top-64 t-w-60 t-h-60 t-hidden xxl:t-flex">
          <img
            src={customization?.iconDocument || iconDocument}
            alt="Document Icon"
          />
        </div>

        <div className="t-flex t-justify-center lg:t-justify-end t-max-w-xl t-w-full xl:t-mt-32 xxl2:t-justify-center t-pr-3 xxl:t-pr-0 xxl2:t-text-center">
          <div>
            <div className="t-flex t-justify-center lg:t-justify-start xxl2:t-justify-center">
              <p
                className={`t-rounded t-px-6 t-py-3 t-bg-delta-200 t-text-delta-700 t-font-semibold ${client}_section_badge`}
              >
                {t('section_4_header')}
              </p>
            </div>
            <div className="" style={{ maxWidth: '640px' }}>
              <p
                className={`Text-header-sub t-mt-4 t-text-center lg:t-text-start ${client}_section_header`}
              >
                {t('section_4_title')}
              </p>

              <p
                className={`Text-normal t-mt-8 t-max-w-128 t-text-center lg:t-text-start ${client}_section_desc`}
              >
                {t('section_4_description')}
              </p>
            </div>
          </div>
        </div>

        <div className="t-hidden lg:t-inline-block lg:t-pt-44 t-relative t-w-full">
          <div
            className={`t-h-0.5 t-bg-delta-700 t-w-full t-absolute t-bottom-5 t-z-0 ${client}_hr`}
          />
          <div className="t-flex t-justify-center t-w-full">
            <div className="t-flex t-justify-between t-max-w-xl t-w-full">
              {texts.map(({ text, className }) => (
                <div
                  key={text}
                  className="t-flex t-flex-col t-items-center t-justify-between"
                >
                  <p
                    className={`Text-small t-ml-3 t-font-semibold t-text-center t-mb-7 ${className} ${client}_custom_font`}
                  >
                    {t(text)}
                  </p>
                  <div
                    className={`t-w-10 t-h-10 t-rounded-full t-border-2 t-border-solid t-border-delta-700 t-bg-gamma-400 t-z-50 t-flex t-justify-center t-items-center ${client}_icon_border`}
                  >
                    <IconCheck className={`t-text-alpha-600 ${client}_icon`} />
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="t-flex t-flex-col t-justify-center t-mt-16 lg:t-hidden">
          <div className="t-grid t-grid-cols-3 t-gap-y-7 t-relative">
            {texts.map(({ text }, idx) => (
              <React.Fragment key={text}>
                <p className="Text-small t-font-semibold t-text-right">
                  {idx % 2 && idx !== texts.length - 1 ? t(text) : ''}
                </p>
                <div className="t-flex t-justify-center t-items-center">
                  <div
                    className={`t-w-10 t-h-10 t-rounded-full t-border-2 t-border-solid t-border-delta-700 t-bg-gamma-400 t-z-50 t-flex t-justify-center t-items-center ${client}_icon_border`}
                  >
                    <IconCheck className={`t-text-alpha-600 ${client}_icon`} />
                  </div>
                </div>
                <p className="Text-small t-font-semibold t-text-left">
                  {!(idx % 2) ? t(text) : ''}
                </p>
              </React.Fragment>
            ))}
            <div
              className={`t-border-l-2 t-border-solid t-border-delta-700 t-h-full t-absolute t-top-0 t-left-1/2 ${client}_icon_border`}
            />
          </div>
          <div className="t-flex t-justify-center t-mt-2">
            <p className="Text-small t-font-semibold t-text-center t-max-w-48">
              {t(texts[texts.length - 1].text)}
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
