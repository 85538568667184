import React from 'react';
import { motion } from 'framer-motion';
import { useTranslation } from 'react-i18next';

import { scrollToElementId } from 'helpers';
import { RegisterButton } from 'components';

const variants = {
  open: { opacity: 1, x: 0 },
  closed: { opacity: 0, x: '-100%' },
};

const ulVariants = {
  open: {
    transition: { staggerChildren: 0.07, delayChildren: 0.2 },
  },
  closed: {
    transition: { staggerChildren: 0.05, staggerDirection: -1 },
  },
};

const liVariants = {
  open: {
    y: 0,
    opacity: 1,
    transition: {
      y: { stiffness: 1000, velocity: -100 },
    },
  },
  closed: {
    y: 50,
    opacity: 0,
    transition: {
      y: { stiffness: 1000 },
    },
  },
};

type NavObject = {
  liClassName?: string;
  elClassName?: string;
  type?: string;
  text?: string;
  to?: string;
  href?: string;
};

interface MobileNavProps {
  navElements: NavObject[];
  showMobileMenu: boolean;
  setShowMobileMenu: (show: boolean) => void;
  registerLink: string;
  customization?: ClientCustomization;
  client?: string;
}

function MobileNav({
  navElements,
  showMobileMenu,
  setShowMobileMenu,
  registerLink,
  customization,
  client,
}: MobileNavProps) {
  const { t } = useTranslation();

  const hideMobileMenu = () => {
    setShowMobileMenu(false);
  };

  return (
    <>
      <motion.nav
        animate={showMobileMenu ? 'open' : 'closed'}
        variants={variants}
        className={`Header-navigation t-z-50 lg:t-hidden ${client}_card_border`}
      >
        <motion.ul className="Header-navigationList" variants={ulVariants}>
          {navElements.map(
            ({ liClassName, elClassName, text, href }, index) => {
              return (
                <motion.li
                  variants={liVariants}
                  whileHover={{ scale: 1.1 }}
                  whileTap={{ scale: 0.95 }}
                  className={liClassName}
                  key={`mobile-nav-${index}`}
                >
                  <p
                    className={elClassName}
                    onClick={() => {
                      scrollToElementId(href);
                      hideMobileMenu();
                    }}
                  >
                    {text}
                  </p>
                </motion.li>
              );
            }
          )}
          {!customization?.hideHeaderRegistrationBtn && (
            <motion.li
              variants={liVariants}
              whileHover={{ scale: 1.1 }}
              whileTap={{ scale: 0.95 }}
              className="md:t-hidden"
            >
              <RegisterButton
                registerLink={registerLink}
                className="button-tertiary t-px-8 t-py-4 t-shadow-lg t-shadow-alpha-200"
                buttonText={t('header_register')}
                onClick={hideMobileMenu}
                customization={customization}
              />
            </motion.li>
          )}
        </motion.ul>
      </motion.nav>
    </>
  );
}

export default MobileNav;
