import React from 'react';
import { useTranslation } from 'react-i18next';
import { Modal } from 'react-bootstrap';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { ReactComponent as IconTriangle } from 'images/triangle.svg';
import { ReactComponent as Cross } from 'images/cross.svg';

interface VideoButtonProps {
  customization?: ClientCustomization;
}

export default function VideoButton({ customization }: VideoButtonProps) {
  const { t } = useTranslation();
  const { trackEvent } = useMatomo();

  const [videoModal, setVideoModal] = React.useState(false);
  const videoRef = React.useRef<HTMLImageElement>(null);

  const [videoHeight, setVideoHeight] = React.useState(100);

  const getHeightHeader = () => {
    if (videoRef?.current?.clientWidth) {
      setVideoHeight(Math.round((videoRef.current.clientWidth * 350) / 622));
    }
  };

  React.useLayoutEffect(() => {
    if (!!videoModal) {
      getHeightHeader();
    }
  }, [videoModal]);

  const handleVideoToggle = () => {
    if (!videoModal) {
      trackEvent({
        category: 'video',
        action: 'video-click-event',
        name: 'watch-video',
      });
    }
    setVideoModal((prev) => !prev);
  };
  return (
    <>
      {videoModal && (
        <Modal
          show={true}
          onHide={handleVideoToggle}
          className="medium"
          backdrop="static"
        >
          <section className="t-whitespace-pre-line t-relative ">
            <button
              onClick={handleVideoToggle}
              className="t-absolute t-right-0"
              type="button"
            >
              <Cross className="t-text-gamma-400 t-w-4 t-h-4" />
            </button>
            <div className="t-flex t-justify-center t-py-10" ref={videoRef}>
              <iframe
                height={videoHeight}
                className="t-w-full"
                src={
                  customization?.embeddedVideoLink ||
                  'https://www.youtube.com/embed/bn9JoRzKtfc'
                }
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
              />
            </div>
          </section>
        </Modal>
      )}

      <button
        onClick={handleVideoToggle}
        className="button-tertiary t-border t-border-solid t-border-alpha-600 typo-eta t-text-alpha-600 t-py-4 t-px-10"
      >
        <IconTriangle className="t-mr-2" />
        <span>{t('video_button_text')}</span>
      </button>
    </>
  );
}
