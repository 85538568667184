import React from 'react';
import { Link } from 'react-router-dom';

function NotFoundPage() {
  return (
    <main>
      <div className="t-bg-gamma-400">
        <div className="t-mx-auto t-max-w-xl t-px-2.5">
          <div className="t-mt-20">
            <h2 className="u-pb-24 u-text-center">
              <span className="Text-header">404: Page Not Found</span>
            </h2>

            <div className="row">
              <div className="col-md-6 offset-md-3 d-flex justify-content-center">
                <p className="t-text-center Text-normal t-mt-5">
                  We're sorry, the page you requested could not be found. Please
                  go back to the home page or contact us at support@ninebarc.com
                </p>
              </div>
            </div>
            <div className="row">
              <div className="col-md-6 offset-md-3 d-flex justify-content-center t-mt-10">
                <Link className="button-secondary" to="/">
                  GO HOME
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
    </main>
  );
}

export default NotFoundPage;
