import React from 'react';
import { useTranslation } from 'react-i18next';

interface PartnershipProps {
  customization?: ClientCustomization;
}

const Partnership = ({ customization }: PartnershipProps) => {
  const { t } = useTranslation();

  const { upperText, logo, link, logoStyle } = customization?.partnership || {};

  const navigateToLink = () => {
    if (link) {
      window.open(link, '_blank');
    }
    return;
  };

  return (
    <>
      {!!upperText && (
        <div className="t-hidden sm:t-inline">
          <p className="typo-theta t-font-semibold t-text-delta-900 t-mb-2">
            {t(upperText)}
          </p>
        </div>
      )}
      {!!logo && (
        <img
          src={logo}
          alt="Partner Logo"
          className={`t-hidden sm:t-inline-block ${
            link ? 't-cursor-pointer' : ''
          } ${logoStyle || ''}`}
          onClick={navigateToLink}
        />
      )}
    </>
  );
};

export default Partnership;
