import React from 'react';
import { useTranslation } from 'react-i18next';

import iconCertificate from 'images/icon-certificates.svg';
import iconSecuredVault from 'images/icon-secured-vault.svg';
import iconShare from 'images/icon-share.svg';
import { ReactComponent as IconCheck } from 'images/icon-check.svg';

interface LandingPageSection2Props {
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection2({
  client,
  customization = {} as ClientCustomization,
}: LandingPageSection2Props) {
  const { t } = useTranslation();

  const {
    customizedTexts,
    section2Advantages = [],
    section2Benefits = [],
  } = customization;

  const advantagesData = [
    {
      text: 'section_2_highlight_1',
      icon: section2Advantages[0] || iconCertificate,
    },
    {
      text: 'section_2_highlight_2',
      icon: section2Advantages[1] || iconSecuredVault,
    },
    {
      text: 'section_2_highlight_3',
      icon: section2Advantages[2] || iconShare,
    },
  ];

  const benefits = [
    section2Benefits[0] || 'section_2_benefit_1',
    section2Benefits[1] || 'section_2_benefit_2',
    section2Benefits[2] || 'section_2_benefit_3',
  ];

  return (
    <div className="t-flex t-justify-center t-mt-10 lg:t-mt-32" id="about-us">
      <div className="t-max-w-xl">
        <div className="t-flex t-flex-col t-items-center">
          <p
            className={`Text-header-small t-text-delta-700 t-text-center ${client}_section_title`}
          >
            {t(
              `${
                customizedTexts?.section2Header ? `${client}_` : ''
              }section_2_header`
            )}
          </p>

          <p
            className={`Text-header-sub t-text-center t-mt-2.5 lg:t-mt-9 ${client}_section_header`}
          >
            {t(
              `${
                customizedTexts?.section2Title ? `${client}_` : ''
              }section_2_title`
            )}
          </p>

          <p
            className={`Text-normal t-max-w-sm t-mt-6 t-px-3 lg:t-mt-8 lg:t-px-0 t-text-center ${client}_section_desc`}
          >
            {t(
              `${
                customizedTexts?.section2Desc ? `${client}_` : ''
              }section_2_description`
            )}
          </p>

          <div className="t-flex t-flex-col lg:t-flex-row t-mt-2.5 lg:t-mt-12">
            {advantagesData.map(({ icon, text }, idx) => (
              <div
                key={text}
                className={`t-flex t-items-center t-mt-7 lg:t-mt-0 ${
                  idx === advantagesData.length - 1 ? '' : 't-mr-8'
                }`}
              >
                <img src={icon} alt={text} />
                <p
                  className={`Text-small t-ml-4 t-text-beta-700 ${client}_custom_font`}
                >
                  {t(text)}
                </p>
              </div>
            ))}
          </div>
        </div>

        <p
          className={`Text-normal t-text-center t-mt-10 md:t-mt-20 lg:t-mt-36 ${client}_section_desc`}
        >
          {t('section_2_benefit_title')}
        </p>

        <div className="t-mt-2.5 lg:t-mt-11 t-flex t-flex-col t-items-center lg:t-flex-row lg:t-justify-between t-gap-x-6">
          {benefits.map((text) => (
            <div key={text} className="t-flex t-mt-7 lg:t-mt-0">
              <div className="t-mr-4 t-flex t-content-center t-items-center">
                <div
                  className={`t-w-8 t-h-8 t-flex t-justify-center t-items-center t-bg-alpha-150 t-rounded-full ${client}_icon_bg`}
                >
                  <IconCheck className={`t-text-alpha-600 ${client}_icon`} />
                </div>
              </div>
              <p
                className={`Text-small t-max-w-72 t-text-beta-700 ${client}_custom_font`}
              >
                {t(text)}
              </p>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
}
