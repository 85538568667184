import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { useMatomo } from '@jonkoops/matomo-tracker-react';

import { NotFoundPage, LandingPage } from 'components';

import 'styles/App.scss?v=1.1';

function App() {
  const { trackPageView } = useMatomo();

  React.useEffect(() => {
    trackPageView();
  }, []);

  return (
    <div className="t-bg-gamma-400">
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="/:token/:client" element={<LandingPage />} />
        <Route path="/:token" element={<LandingPage />} />
        <Route path="/*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
