import React from 'react';
import { useMatomo } from '@jonkoops/matomo-tracker-react';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import config from 'helpers/configHelper';

import {
  CustomClientSection1,
  Footer,
  Header,
  LandingPageSection1,
  LandingPageSection2,
  LandingPageSection3,
  LandingPageSection4,
  LandingPageSection5,
  LandingPageSection6,
  LandingPageSection7,
  LandingPageSection8,
  LandingPageSection9,
} from 'components';

import {
  CLIENT_CUSTOMIZATIONS,
  CLIENT_KEYS,
} from 'constants/clientCustomizations';

export default function LandingPage() {
  const { pushInstruction } = useMatomo();
  const { token, client } = useParams();
  const { t } = useTranslation();

  const baseFeUrl = config.feUrl;
  const customization: ClientCustomization =
    CLIENT_CUSTOMIZATIONS[client || ''];

  const currentUrl = window.location.href;
  const pathNameRegex = /http[s]*:\/\/[^\/]+(\/.+)/;
  const matches = currentUrl.match(pathNameRegex) || '';

  let registerLink = `${baseFeUrl}/register${matches[1] ? matches[1] : ''}`;

  switch (client) {
    case CLIENT_KEYS.DSG:
      registerLink = `${config.feUrlDsg || ''}/register`;
      break;
    case CLIENT_KEYS.SPKV:
      registerLink = `${config.feUrlSPKV || ''}/register`;
      break;
    default:
      break;
  }

  React.useEffect(() => {
    // consider all client visitor as a unique user
    pushInstruction('setUserId', client || 'Ninebarc');
  }, [client]);

  React.useEffect(() => {
    if (customization?.favicon) {
      changeFavicon();
    }
  }, [customization, client]);

  const changeFavicon = () => {
    let link = document.querySelector("link[rel~='icon']") as HTMLLinkElement;
    if (!link) {
      link = document.createElement('link');
      link.rel = 'icon';
      document.getElementsByTagName('head')[0].appendChild(link);
    }

    if (customization?.favicon) {
      document.title = t(`${client}_page_title`);
      link.href = `/${client}_favicon.png`;
    } else {
      document.title = 'Landingpage von Ninebarc';
      link.href = '/favicon.ico';
    }
  };

  return (
    <div>
      <Header
        registerLink={registerLink}
        token={token}
        client={client}
        customization={customization}
      />

      <div className="t-mt-10">
        <LandingPageSection1
          registerLink={registerLink}
          client={client}
          customization={customization}
        />
        {customization?.customClientSection1 && (
          <CustomClientSection1
            registerLink={registerLink}
            client={client}
            customization={customization}
          />
        )}
        <LandingPageSection2 client={client} customization={customization} />
        <LandingPageSection3 client={client} customization={customization} />
        <LandingPageSection4 client={client} customization={customization} />
        <LandingPageSection5 client={client} customization={customization} />
        <LandingPageSection6
          registerLink={registerLink}
          client={client}
          customization={customization}
        />
        <LandingPageSection7 client={client} customization={customization} />
        <LandingPageSection8 client={client} customization={customization} />
        <LandingPageSection9
          registerLink={registerLink}
          client={client}
          customization={customization}
        />
      </div>

      <Footer client={client} customization={customization} />
    </div>
  );
}
