import React from 'react';
import { useTranslation } from 'react-i18next';

import imgFeatureBackground from 'images/img-feature-background.png';
import imgFeatureBackgroundMobile from 'images/img-feature-background-mobile.png';
import iconSecurity from 'images/icon-security.svg';
import iconEmergency from 'images/icon-emergency-access.svg';
import iconFlexible from 'images/icon-flexible.svg';

interface LandingPageSection3Props {
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection3({
  client,
  customization = {} as ClientCustomization,
}: LandingPageSection3Props) {
  const { t } = useTranslation();

  const { customizedTexts, features = [] } = customization;

  const featuresData = [
    {
      text: 'section_3_feature_1',
      icon: features[0] || iconSecurity,
      className: 't-max-w-36',
    },
    {
      text: 'section_3_feature_2',
      icon: features[1] || iconEmergency,
      className: 't-max-w-36',
    },
    {
      text: 'section_3_feature_3',
      icon: features[2] || iconFlexible,
      className: 't-max-w-48',
    },
  ];

  return (
    <div id="features">
      <img
        src={
          customization?.section3Background?.mobileDE ||
          imgFeatureBackgroundMobile
        }
        alt="Ninebarc platform"
        className="t-w-full t-mt-20 lg:t-hidden"
      />
      <div className="t-relative t-mt-28 xl:t-mt-32 xl:t-pb-32 t-flex t-justify-center t-overflow-hidden">
        <div className="t-absolute t-top-0 -t-right-9 t-max-w-sm t-hidden lg:t-inline-block">
          <img
            src={
              customization?.section3Background?.desktopDE ||
              imgFeatureBackground
            }
            alt="Ninebarc Features"
            className="t-w-96 xl:t-w-auto"
          />
        </div>

        <div className="t-max-w-xl t-w-full xl:t-mt-36 t-px-3">
          <div className="t-flex t-justify-center lg:t-justify-start xxl2:t-justify-center">
            <p
              className={`t-rounded t-px-6 t-py-3 t-bg-delta-200 t-text-delta-700 t-font-semibold ${client}_section_badge`}
            >
              {t('section_3_header')}
            </p>
          </div>

          <div className="t-flex t-flex-col t-items-center lg:t-inline-block xxl2:t-flex xxl2:t-flex-col xxl2:t-items-center">
            <div
              className="t-text-center lg:t-text-left"
              style={{ maxWidth: '640px' }}
            >
              <p className={`Text-header-sub t-mt-4 ${client}_section_header`}>
                {t('section_3_title')}
              </p>

              <p
                className={`Text-normal t-mt-8 lg:t-pr-16 ${client}_section_desc`}
              >
                {t(
                  `${
                    customizedTexts?.section3Desc ? `${client}_` : ''
                  }section_3_description`
                )}
              </p>
            </div>

            <div>
              <div className="t-flex t-flex-col lg:t-mt-32 lg:t-flex-row t-items-center">
                {featuresData.map(({ icon, text, className }, idx) => (
                  <div
                    key={text}
                    className={`t-flex t-items-center t-mt-7 lg:t-mt-0 t-w-72 ${
                      idx === featuresData.length - 1 ? '' : 'lg:t-mr-24'
                    }`}
                  >
                    <div
                      className={`t-bg-delta-200 t-rounded t-flex t-justify-center t-items-center ${client}_icon_bg`}
                      style={{ width: '72px', height: '72px' }}
                    >
                      <img src={icon} alt={text} />
                    </div>
                    <p
                      className={`Text-small t-ml-3 t-font-semibold ${className} ${client}_custom_font`}
                    >
                      {t(text)}
                    </p>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
