import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegisterButton, VideoButton } from 'components';

import imgBackground from 'images/img-bottom-background.png';
import { ReactComponent as IconPointer } from 'images/icon-pointer.svg';

interface LandingPageSection1Props {
  registerLink: string;
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection9({
  registerLink,
  client,
  customization,
}: LandingPageSection1Props) {
  const { t } = useTranslation();

  return (
    <div className="t-flex t-justify-center t-mt-32 t-relative">
      <img
        src={customization?.section9Background || imgBackground}
        alt="Bottom background"
        className="t-absolute t-bottom-0 t-w-full t-z-0"
      />
      <div className="t-max-w-xl t-flex t-flex-col t-items-center t-z-50">
        <p
          className={`Text-header-sub t-text-center t-max-w-136 ${client}_section_title`}
        >
          {t('section_9_title')}
        </p>

        <p
          className={`Text-normal t-mt-10 t-max-w-136 t-text-center ${client}_section_desc`}
        >
          {t('section_9_description')}
        </p>

        <div className="t-flex t-flex-col lg:t-flex-row t-mt-5 lg:t-mt-11 t-mb-16 t-relative">
          {!customization?.noVideoBtn && (
            <VideoButton customization={customization} />
          )}
          <RegisterButton
            registerLink={registerLink}
            client={client}
            customization={customization}
          />

          <IconPointer
            className={`t-absolute -t-right-28 -t-top-5 t-mt-6 t-hidden lg:t-inline-block t-text-alpha-600 ${client}_pointer`}
            style={{ transform: 'rotate(-19deg)' }}
          />
        </div>
      </div>
    </div>
  );
}
