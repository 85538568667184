import { LANGUAGES } from 'constants/common';

export const isCurrentLangGerman = (currentLang) =>
  currentLang === LANGUAGES.DE;

export const scrollToElementId = (elementId) => {
  setTimeout(async () => {
    const element = document.getElementById(elementId);
    await element?.scrollIntoView({ behavior: 'smooth' });
  }, 100);
};

export function localizeNumber(
  number: string | number,
  lngCode: string
): string {
  const stringifiedNumber = String(number);
  switch (lngCode) {
    case LANGUAGES.DE:
      return stringifiedNumber.replace('.', ',');
    default:
      return stringifiedNumber.replace(',', '.');
  }
}
