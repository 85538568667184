import React from 'react';
import { useTranslation } from 'react-i18next';
import DOMPurify from 'dompurify';

import { localizeNumber } from 'helpers';
import { RegisterButton } from 'components';

import { ReactComponent as IconPriceRectangle } from 'images/icon-price-rectangle.svg';
import { ReactComponent as IconCheck } from 'images/icon-check.svg';
import { ReactComponent as IconPointer } from 'images/icon-pointer.svg';

const priceBenefits = [
  {
    highlight: 'section_6_price_benefit_1_highlight',
    text: 'section_6_price_benefit_1_text',
  },
  {
    highlight: 'section_6_price_benefit_2_highlight',
    text: 'section_6_price_benefit_2_text',
  },
  {
    highlight: 'section_6_price_benefit_3_highlight',
    text: 'section_6_price_benefit_3_text',
  },
  {
    highlight: 'section_6_price_benefit_4_highlight',
    text: 'section_6_price_benefit_4_text',
  },
  {
    highlight: 'section_6_price_benefit_5_highlight',
    text: 'section_6_price_benefit_5_text',
  },
  {
    highlight: 'section_6_price_benefit_6_highlight',
    text: 'section_6_price_benefit_6_text',
  },
  {
    highlight: 'section_6_price_benefit_7_highlight',
    text: 'section_6_price_benefit_7_text',
  },
  {
    highlight: 'section_6_price_benefit_8_highlight',
    text: 'section_6_price_benefit_8_text',
  },
];

interface LandingPageSection6Props {
  registerLink: string;
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection6({
  registerLink,
  client,
  customization = {} as ClientCustomization,
}: LandingPageSection6Props) {
  const { t } = useTranslation();

  const {
    noOneTimePrice,
    noPricingSection,
    customizedTexts,
    monthlyPrice,
    noPriceQuotes,
    customPriceSectionTitle,
    customPriceSectionDesc,
    yearlyPriceRecommended,
    customYearlyPrice,
    customLifetimePrice,
    customPriceBenefitIcon,
  } = customization;
  const replacedWith = customizedTexts?.section6Texts ? `${client}_` : '';
  // In case the yearly plan is placed by monthly one

  if (noPricingSection) return null;

  return (
    <div className="t-flex t-justify-center t-mt-32" id="prices">
      <div className="">
        <p className={`Text-header-sub t-text-center ${client}_section_header`}>
          {t(customPriceSectionTitle || 'section_6_title')}
        </p>
        {!customization?.noTrial && (
          <p
            className={`Text-normal t-mt-8 t-text-center ${client}_section_desc`}
          >
            {t(customPriceSectionDesc || 'section_6_description')}
          </p>
        )}

        {!noPriceQuotes && (
          <div
            className={`t-flex t-flex-col t-items-center lg:t-items-start lg:t-flex-row ${
              !!noOneTimePrice ? 't-justify-center' : ''
            } ${!customization?.noTrial ? 't-mt-11' : ''}`}
          >
            <div
              className={`t-p-7 t-relative ${
                !noOneTimePrice
                  ? 't-shadow-md t-border t-border-solid t-border-delta-200 t-rounded lg:t-mr-8'
                  : 't-flex t-flex-col t-items-center'
              }`}
              style={{ width: '352px' }}
            >
              {yearlyPriceRecommended && (
                <div className="t-flex t-absolute t-top-6 -t-right-2">
                  <p
                    className="t-py-1 t-px-3 t-bg-alpha-150 t-text-alpha-600 t-shadow t-shadow-alpha-150 t-rounded-sm t-relative"
                    style={{ fontSize: '12px', lineHeight: '16px' }}
                  >
                    {t('section_6_price_one_time_recommended')}
                    <IconPriceRectangle className="t-absolute -t-top-1 t-right-0" />
                  </p>
                </div>
              )}
              <p
                className={`Text-normal t-text-beta-700 ${client}_custom_font`}
                style={{ fontWeight: 500 }}
              >
                {t(`${replacedWith}section_6_price_yearly_title`)}
              </p>
              {!monthlyPrice ? (
                <p
                  className={`t-mt-5 ${client}_custom_font`}
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t(customYearlyPrice || 'section_6_price_yearly_price')
                    ),
                  }}
                />
              ) : (
                <p className={`t-mt-5 ${client}_custom_font`}>
                  <span className="Text-small t-font-normal t-text-epsilon-700 t-mr-2">
                    {t('section_6_price_monthly_type')}
                  </span>
                  <span
                    className="t-text-beta-700"
                    style={{ fontSize: '44px', lineHeight: '54px' }}
                  >
                    {t(`section_6_price_monthly_price`, {
                      price: localizeNumber(monthlyPrice, 'de'),
                    })}
                  </span>
                </p>
              )}
              <p
                className={`Text-small t-font-normal t-mt-6 t-text-beta-500 ${client}_custom_font`}
              >
                {t(`${replacedWith}section_6_price_yearly_desc`)}
              </p>
            </div>

            {!noOneTimePrice && (
              <div
                className="t-p-7 t-shadow-md t-border t-border-solid t-border-delta-200 t-rounded t-relative t-mt-4 lg:t-mt-0"
                style={{ width: '352px' }}
              >
                {!yearlyPriceRecommended && (
                  <div className="t-flex t-absolute t-top-6 -t-right-2">
                    <p
                      className="t-py-1 t-px-3 t-bg-alpha-150 t-text-alpha-600 t-shadow t-shadow-alpha-150 t-rounded-sm t-relative"
                      style={{ fontSize: '12px', lineHeight: '16px' }}
                    >
                      {t('section_6_price_one_time_recommended')}
                      <IconPriceRectangle className="t-absolute -t-top-1 t-right-0" />
                    </p>
                  </div>
                )}
                <p
                  className="Text-normal t-text-beta-700"
                  style={{ fontWeight: 500 }}
                >
                  {t('section_6_price_one_time_title')}
                </p>
                <p
                  className="t-mt-5"
                  dangerouslySetInnerHTML={{
                    __html: DOMPurify.sanitize(
                      t(customLifetimePrice || 'section_6_price_one_time_price')
                    ),
                  }}
                />
                <p className="Text-small t-font-normal t-mt-6 t-text-beta-500">
                  {t('section_6_price_one_time_desc')}
                </p>
              </div>
            )}
          </div>
        )}

        <div
          className="t-flex t-flex-col t-items-center lg:t-grid lg:t-grid-cols-2 lg:t-gap-x-8 lg:t-gap-y-6 t-mt-9"
          style={{ maxWidth: '736px' }}
        >
          {priceBenefits.map(({ highlight, text }) => (
            <div
              className="t-flex t-items-center t-max-w-96 t-mt-5 lg:t-mt-0 t-px-3 lg:t-px-0"
              key={text}
            >
              <div>
                <div
                  className={`t-w-8 t-h-8 t-flex t-justify-center t-items-center t-bg-alpha-150 t-rounded-full ${client}_icon_bg`}
                >
                  {!!customPriceBenefitIcon ? (
                    <img
                      src={customPriceBenefitIcon}
                      alt="price benefit icon"
                      className={`t-text-alpha-600 ${client}_icon`}
                    />
                  ) : (
                    <IconCheck className={`t-text-alpha-600 ${client}_icon`} />
                  )}
                </div>
              </div>

              <p
                className="t-ml-5 t-text-beta-700"
                style={{ fontSize: '14px' }}
              >
                <span className="t-font-semibold">{t(highlight)}</span>
                <span className={`${client}_custom_font`}>{t(text)}</span>
              </p>
            </div>
          ))}
        </div>

        <div className="t-flex t-justify-center t-mt-12 t-relative">
          <RegisterButton
            registerLink={registerLink}
            client={client}
            className="button-primary t-px-8 t-py-4 t-shadow-lg t-shadow-alpha-200 lg:t-ml-8"
            customization={customization}
          />

          <IconPointer
            className={`t-absolute t-right-16 t-top-4 t-hidden lg:t-inline-block t-text-alpha-600 ${client}_pointer`}
          />
        </div>
      </div>
    </div>
  );
}
