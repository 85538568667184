import React from 'react';
import { useTranslation } from 'react-i18next';

import { RegisterButton, VideoButton } from 'components';

import imgBackground from 'images/img-header-background.png';
import imgBackgroundMobile from 'images/img-header-background-mobile.png';
import { ReactComponent as IconPointer } from 'images/icon-pointer.svg';

interface LandingPageSection1Props {
  registerLink: string;
  client?: string;
  customization?: ClientCustomization;
}

export default function LandingPageSection1({
  registerLink,
  client,
  customization = {} as ClientCustomization,
}: LandingPageSection1Props) {
  const { t } = useTranslation();

  const headBgRef = React.useRef<HTMLImageElement>(null);

  const [headerBgHeight, setHeaderHeight] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.screen.width);

  const isMobile = screenWidth < 1200;

  const getHeightHeader = () => {
    if (headBgRef?.current?.clientHeight) {
      setHeaderHeight(headBgRef.current.clientHeight);
    }
    setScreenWidth(window.innerWidth);
  };

  window.onresize = getHeightHeader;
  window.onload = getHeightHeader;

  React.useLayoutEffect(() => {
    getHeightHeader();
  }, []);

  const {
    mobileCooperationLogo,
    section1Background,
    customizedTexts,
    noVideoBtn,
    partnership,
  } = customization;

  return (
    <div
      className="t-relative"
      style={{
        height: `${isMobile ? 'auto' : `${headerBgHeight}px`}`,
      }}
    >
      {mobileCooperationLogo ? (
        <div className="t-flex t-flex-col t-items-center t-pb-8 sm:t-hidden">
          <p className="typo-theta t-font-semibold t-text-delta-900 t-mb-2">
            {t(`${client}_in_partnership_with`)}
          </p>
          <img
            src={partnership?.logo}
            alt={`${client} partnership`}
            className="t-w-60"
          />
        </div>
      ) : null}
      <img
        src={section1Background?.desktopDE || imgBackground}
        alt="Ninebarc platform"
        className="t-w-full t-absolute t-z-0 t-pt-40 t-hidden xl:t-inline-block xl:t-pt-32 xxl2:t-pt-20 t-top-0"
        ref={headBgRef}
      />
      <div className="t-flex t-flex-col t-items-center t-bg-transparent">
        <p className={`Text-header t-text-center ${client}_page_title`}>
          {t(
            `${
              customizedTexts?.section1Title ? `${client}_` : ''
            }section_1_title`
          )}
        </p>

        <p
          className={`Text-normal t-mt-5 lg:t-mt-10 t-text-center ${client}_section_desc`}
        >
          {t(
            `${
              customizedTexts?.section1Desc ? `${client}_` : ''
            }section_1_description`
          )}
        </p>

        <div className="t-flex t-flex-col lg:t-flex-row t-mt-5 lg:t-mt-11 t-relative">
          {!noVideoBtn && <VideoButton customization={customization} />}
          <RegisterButton
            registerLink={registerLink}
            client={client}
            customization={customization}
          />

          <IconPointer
            className={`t-absolute -t-right-32 t-mt-6 t-hidden lg:t-inline-block t-text-alpha-600 ${client}_pointer`}
            style={{ transform: 'rotate(10deg)' }}
          />
        </div>

        <img
          src={section1Background?.mobileDE || imgBackgroundMobile}
          alt="Ninebarc platform"
          className="t-w-full xl:t-hidden t-mt-4"
        />
      </div>
    </div>
  );
}
