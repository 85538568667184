const isDevLocal = process.env.REACT_APP_NODE_ENV === 'local';
const isProduction = process.env.REACT_APP_NODE_ENV === 'production';

export default {
  isDevLocal,
  isProduction,
  feUrl: process.env.REACT_APP_FE_URL,
  beUrl: process.env.REACT_APP_BE_URL,
  matomoBaseUrl: process.env.REACT_APP_MATOMO_BASE_URL,
  matomoSiteId: Number(process.env.REACT_APP_MATOMO_SITE_ID || 0),

  feUrlDsg: process.env.REACT_APP_FE_URL_DSG,
  feUrlSPKV: process.env.REACT_APP_FE_URL_SPKV,
};
